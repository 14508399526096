<template>
  <div>
    <br>
    <el-form :inline='true' label-position='right' class='table-search'>
      <el-form-item>
        <el-date-picker
            v-model="time_value"
            type="monthrange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始月"
            @blur="handleQuery"
            value-format="yyyy-MM-dd"
            end-placeholder="结束月">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select @change="handleQuery" clearable placeholder="选择分组" v-model="selectInfo.dept_id">
          <el-option :key="index" :label="item?.name" :value="item?.id" v-for="(item,index) in groupList">
            <span style="float: left"> {{ item?.name }} </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click='handleQuery' type='primary' icon='el-icon-search'>查询</el-button>
        <export-btn ref="refExportLink"
                    :can-export="userPermissions.indexOf('kol-analysis-export') !== -1"
                    :lintTitle="'导出列表'"
                    class="download"
                    @export="downLoad"/>
      </el-form-item>
    </el-form>
    <div class="default-table">
      <el-table :data="dataList" ref="multipleTable" row-key="id"
                show-summary
                @sort-change='changeTableSort'
                v-loading="tableLoading" border>
        <el-table-column
            type="selection"
            width="50">
        </el-table-column>
        <el-table-column align="center" label="月份" width="120"
                         show-overflow-tooltip prop="month">
          <template slot-scope="{row}">
            {{ row?.month.slice(0, 7) || '-' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="部门-组" min-width="220"
                         show-overflow-tooltip prop="dept_name">
          <template slot-scope="{row}">
            <el-link @click="detailPage(row)" size="mini" type="primary" v-if="userPermissions.indexOf('kol-analysis-item-list') !== -1">
              <span >{{ row.dept_name }} </span>
            </el-link>
            <span v-else>{{ row.dept_name }}</span>

          </template>
        </el-table-column>
        <el-table-column align="right" label="收入合计"
                         width="120" show-overflow-tooltip :formatter="formatNumber" sortable="custom"
                         prop="income_total"></el-table-column>
        <el-table-column align="right" label="广告收入" width="120" prop="ad_total"
                         :formatter="formatNumber" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column align="right" label="直播佣金收入" width="120" prop="live_commission_total"
                         :formatter="formatNumber" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column align="right" label="短视频收入" width="120" prop="short_video_total"
                         :formatter="formatNumber" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column align="right" label="直播坑位费" width="120" prop="live_position_total"
                         :formatter="formatNumber" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column align="right" label="费用合计" width="120" prop="cost_total"
                         show-overflow-tooltip :formatter="formatNumber" sortable="custom"></el-table-column>
        <el-table-column align="right" label="网红分成" width="120" prop="kol_divide_total"
                         show-overflow-tooltip :formatter="formatNumber" sortable="custom"></el-table-column>
        <el-table-column align="right" label="MCN推广" prop="promotion_total" show-overflow-tooltip
                         :formatter="formatNumber" sortable="custom" width="120"></el-table-column>
        <el-table-column align="right" label="直播投放金" prop="live_put_total" show-overflow-tooltip
                         :formatter="formatNumber" sortable="custom" width="120"></el-table-column>
        <el-table-column align="right" label="报销" prop="expenses_total" show-overflow-tooltip
                         :formatter="formatNumber" sortable="custom" width="120"></el-table-column>
        <el-table-column align="right" label="人工成本" prop="labor_cost" show-overflow-tooltip
                         sortable="custom" :formatter="formatNumber" width="120"></el-table-column>
        <el-table-column align="right" label="返点费用" prop="rebate_total" show-overflow-tooltip
                         sortable="custom" :formatter="formatNumber" min-width="120"></el-table-column>
        <el-table-column align="right" label="预计固定费用" prop="fixed_total" show-overflow-tooltip
                         :formatter="formatNumber" sortable="custom" width="100"></el-table-column>
        <el-table-column align="right" label="利润" prop="profit" show-overflow-tooltip
                         :formatter="formatNumber" sortable="custom" width="100"></el-table-column>
        <el-table-column align="center" label="总粉丝数" prop="fans_total" show-overflow-tooltip
                         sortable="custom" width="100"></el-table-column>
        <el-table-column align="center" label="新增粉丝数" prop="incr_total" show-overflow-tooltip
                         sortable="custom" width="100"></el-table-column>
        <el-table-column align="right" label="粉丝单价" prop="fans_price" show-overflow-tooltip
                         :formatter="formatNumber" sortable="custom" width="100"></el-table-column>
        <el-table-column align="right" label="单粉收入" prop="single_fan_income" show-overflow-tooltip
                         :formatter="formatNumber" sortable="custom" width="100"></el-table-column>

      </el-table>
      <br>

      <el-row>
        <el-col :span='24' style='text-align: right'>
          <Pagination :limit.sync='pagingData.page_size'
                      :page.sync='pagingData.current_page'
                      :total='pagingData.total'
                      @pagination='getList'/>
        </el-col>
      </el-row>

    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'TeamList',
  data() {
    return {
      dataList: [],
      pagingData: {
        page_size: 20,
        current_page: 1,
        total: 0
      },
      tableLoading: true,
      selectInfo: { dept_id: '' },
      time_value: [],
      groupList: [],
      orderSort:[]
    }
  },
  components: {
    ExportBtn
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }

      this.orderSort = orderSort
      this.getList()
    },

    async downLoad() {
      try {
        var isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          let selectInfo = { ...this.selectInfo, is_export: true }
          if (selectInfo.order.length === 0) {
            delete selectInfo.order
          }
          let response = await this.$api.exportKolGroupList(selectInfo)
          let name = `红人项目组模型列表`
          this.$refs.refExportLink.exportSaveXlsx(response, name)
        }
      } catch (e) {
        this.$message.warning('浏览器不支持导出文件')
      }
    },
    formatNumber(row, col) {
      return this.$utils.numberFormat(row[col.property], 2, '.', ',', 'round')
    },
    detailPage(row) {
      const { href } = this.$router.resolve({
        name: 'modelTeamDetail',
        params: { id: row.dept_id },
        query: {
          month: row.month
        }
      })
      window.open(href, '_blank')
    },
    async getList() {
      this.tableLoading = false
      Object.assign(this.selectInfo, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      }, { order: this.orderSort })
      const { list, page_info } = await this.$api.modelGroupList(this.selectInfo)
      this.dataList = list
      this.pagingData = page_info
      this.isLoading = false
    },
    handleQuery() {
      if (this.time_value?.[0]) {
        this.selectInfo.begin_start = this.time_value?.[0]
        this.selectInfo.begin_end = this.time_value?.[1]
      } else {
        delete this.selectInfo.begin_start
        delete this.selectInfo.begin_end
      }
      if (this.selectInfo.dept_id === '') {
        delete this.selectInfo.dept_id
      }
      this.pagingData.current_page = 1
      this.getList()
    },
    async getGroup() {
      this.groupList = await this.$api.modelGetDepList(this.selectInfo)
    }
  },
  mounted() {
    this.handleQuery()
    this.getGroup()

  }
}
</script>

<style>
.el-table .warning-row {
  background: #f9f6f1;
  color: white;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<style scoped>
.download {
  margin: 0 10px;
}
</style>
